<template>
  <div class="wraper pagesxruku">
    <!--<el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1">
        <el-input v-model="searchForm.title" size="small" clearable style="width: 200px!important;" placeholder="详情搜索">
          <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
        </el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="add" style="margin-left: 10px;">申购单入库
        </el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="add" style="margin-left: 10px;">新增入库
        </el-button>
        <el-button style="margin-left: 10px;" size="small" type="danger" plain icon="el-icon-delete" @click="delSlct">
          取消入库
        </el-button>

        <el-button size="small" type="primary" icon="el-icon-download">导出</el-button>
        <el-button size="small" type="primary" icon="el-icon-printer">打印</el-button>

      </div>
    </el-row>
    <el-table :data="DataList" height="calc( 100% - 100px)"
              style="width: 100%;"
              stripe border size="small">
      <el-table-column type="selection" width="55"/>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="sp_status" label="状态" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.sp_status==0" style="color: #F56C6C">待入库</span>
          <span v-if="scope.row.sp_status==1" style="color: #67c23a">已入库</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="申请人" width="120" :show-overflow-tooltip="true"/>
      <el-table-column prop="cdate" label="申请时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.cdate?scope.row.cdate.substr(0, 10):'-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="storename" label="入库仓库" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>仓库1</span>
        </template>
      </el-table-column>
      <el-table-column prop="plan_user" label="经办人" width="120" :show-overflow-tooltip="true"/>
      <el-table-column prop="cdate" label="入库时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.cdate?scope.row.cdate.substr(0, 10):'-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="入库数" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">
            2条>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="160"/>
      <el-table-column label="操作" width="230" fixed="right">
        <template slot-scope="scope">
          <el-tag size="mini" style="cursor: pointer" @click="view(scope.row)">查看</el-tag>
          <el-tag size="mini" style="margin-left: 10px;cursor: pointer;color: #409eff;" @click="view(scope.row)">匹配</el-tag>
          <el-tag size="mini" type="danger" style="cursor: pointer;margin-left: 10px;" @click="view(scope.row)">删除</el-tag>
          <el-tag type="warning" size="mini" style="margin-left: 10px;cursor: pointer;"
                  @click="view(scope.row)">修改
          </el-tag>
        </template>
      </el-table-column>
    </el-table>

    -
    <el-pagination class="cus_page"
                   @size-change="handleSizeChange"
                   @current-change="paginate"
                   :current-page.sync="page.current_page"
                   :page-sizes="[10, 50, 100]"
                   :page-size="page.per_page"
                   layout="total,sizes, prev, pager, next"
                   :total="page.count">
    </el-pagination>
    -->
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
    }
  },
  mounted() {
    this.getList();

    this.getArea();
  },
  methods: {
    getList() {
      let _this = this
      let params = {}, arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      this.$http.post("/api/sx_sg_list", params).then(res => {
        this.DataList = res.data.data;
        this.page = res.data.page
        this.page_hc = res.data.page
      })
    },

    onSearch() {
      this.page.current_page = 1
      this.getList()
    },

    chooseHC(row){
      this.$parent.$parent.$parent.$parent.chooseHC(row)
    },

    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
  }
}
</script>

<style scoped lang="less" type="text/less">

.pagesxruku {
  background-image: url('../imgss/33333333.png');
  background-repeat: no-repeat;

  div {
    box-sizing: border-box
  }
  position: relative;
  .addDialog {

    .el-dialog__wrapper {
      position: absolute !important;
    }

    .v-modal {
      position: absolute !important;
    }

    //border: 1px solid #409eff;
    box-shadow: 0 0 5px #409eff81;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: calc(100% - 10px);
    height: calc(100% - 10px);


    .myDialogBox {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0 !important;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        width: 100%;
        height: calc(100% - 70px);
        padding: 20px 20px 10px;

        .shadow {
          box-shadow: 0 0 5px #409eff81;
        }

        .titleBar {
          width: 100%;
          height: 30px;
          background-color: #2469f351;
          font-size: 14px;
        }

        .titleBar .div {
          width: 100%;
          height: calc(100% - 2px);
          padding-left: 22px;
          border-left: none;
          color: #303133;
          font-weight: 700
        }

        .el-form-item {
          margin-bottom: 0;

          .el-textarea__inner {
            margin: 10px 0;
          }
        }

        .cgInput {
          max-width: 120px;

          .el-input__inner {
            color: #F56C6C;
          }
        }

        .cgInput input::-webkit-outer-spin-button,
        .cgInput input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        .cgInput input[type="number"] {
          -moz-appearance: textfield;
        }

      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input, .el-select, .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box, .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    //overflow: hidden;
    background-repeat: no-repeat;
    //cursor: pointer;
  }

  .uploadBox:hover {
    border-color: #409eff !important;
    color: #409eff;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}



</style>

